<template lang="">
<div id="js-loader" class="loader" style="display: block;"><div class="loader-animation"></div></div>
</template>
<script>
export default {};
</script>
<style lang="css">
.loader {
  background: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100000;
}
.loader-animation,
.loader-animation:after,
.loader-animation:before {
  background: #8fce00 !important;
  -webkit-animation: load1 1s ease-in-out infinite;
  animation: load1 1s ease-in-out infinite;
  width: 1em;
  height: 4em;
}
.loader-animation {
  height: 10px;
  left: 50%;
  margin: -5px 0 0 -5px;
  position: absolute;
  top: 50%;
  width: 10px;
  color: #8fce00 !important;
  text-indent: -9999em;
  font-size: 10px;
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader-animation:after,
.loader-animation:before {
  position: absolute;
  top: 0;
  content: "";
}
.loader-animation:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader-animation:after {
  left: 1.5em;
}
#loading {
  position: absolute;
  left: 50%;
  top: 30%;
  text-align: center;
}
#loader-bg,
.loader,
.loader_elsetop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 100000;
}
.loader-animation {
  position: relative;
}
.loader-animation .img_wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  to {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  to {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
</style>