export default {
  LIST: {
    title: "ユーザー",

    sub_title: "ユーザー一覧",

    breadcrumb: {
      dashboard: "ダッシュボード ",

      facility_staff: "ユーザー",
    },
    grid: {
      profile_logo: "画像",

      user_name: "ユーザー名",

      user_code: "ユーザーコード",

      mail_address: "メールアドレス",

      tel: "TEL",

      password: "パスワード",

      authority: "権限",

      position: "役職",

      order: "並び順",

      has_been_selected: "が選択済みです",
    },
    button: {
      create: "ユーザー追加",

      update: "編集",

      show: "表示",

      delete: "アーカイブ",

      search: "検索",

      cancel: "キャンセル",

      execution: "実行",
    },
    delete: {
      title: "確認",

      description: "削除しますか？",

      execution: "実行",

      cancel: "キャンセル",
    },
    search: {
      authority: "権限",
      user_name: "ユーザー名",
      user_code: "ユーザーコード",
      email: "メールアドレス",
      un_select: "未選択",
      no_results: "検索結果はありません",
    },
    place_holder: {
      search_input: "ユーザー名 ユーザーコード メールアドレス 電話番号",
    },
    staff_list: "ユーザー一覧",

    pagination_url: "user",
  },
  SHOW: {
    title: "ユーザー",

    sub_title: "ユーザー詳細",

    breadcrumb: {
      dashboard: "ダッシュボード ",

      facility_staff: "ユーザー",

      details: "詳細",

      view: "表示",

      edit: "編集",
    },

    grid: {
      name: "名前",

      kathakana: "カタカナ カタカナ",

      user_code: "ユーザーコード",

      address: "連絡先",

      mail_address: "(メールアドレス)",

      tel: "(TEL)",

      authority: "権限",

      hire_date: "入社日",

      is_admin: "管理",

      is_user: "ユーザー",

      section: "部門",

      group: "グループ",

      position: "役職",

      introduce: "紹介文",

      nickname: "ニックネーム",

      sex: "性別",
    },
    button: {
      create: "新規投稿",

      update: "編集",

      show: "表示",

      delete: "アーカイブ",

      search: "検索",
    },

    staff_list: "ユーザー一覧",
  },
  CREATE: {
    title: "ユーザー",

    sub_title: "サンクスカード一覧",

    breadcrumb: {
      dashboard: "ダッシュボード ",

      facility_staff: "ユーザー",

      new_post: "新規投稿",
    },
    grid: {
      name: "名前",

      required: "必須",

      last_name: "姓",

      last_name_katakana: "セイ",

      first_name: "名",

      first_name_katakana: "メイ",

      alphabet: "アルファベット",

      alphabet_dec: "姓名の間にスペースを入力しないでください。",

      user_code: "ユーザーコード",

      user_code_dec: "社員コードなど個人を重複のない個人を特定できるコード。",

      contact_address: "連絡先",

      mail_address: "メールアドレス",

      phone: "任意",

      tel: "TEL",

      password: "パスワード",

      password_dec: "半角英数と記号（-_!）が入力可能",

      authority: "権限",

      sex: "性別",

      select_sex: "性別を選択",

      male: "男",

      female: "女",

      select_authority: "権限を選択",

      admin: "管理者",

      editor: "編集者",

      administrator: "運営者",

      hire_date: "入社日",

      is_admin: "管理",

      is_user: "ユーザー",

      section: "部門",

      group: "グループ",

      position: "役職",

      introduce: "紹介文",

      nickname: "ニックネーム",
    },
    button: {
      create: "新規投稿",

      update: "編集",

      show: "表示",

      delete: "アーカイブ",

      search: "検索",

      cancel: "キャンセル",

      execution: "実行",
    },
    status: {
      release: "公開",

      draft: "下書き",

      archive: "アーカイブ",

      un_select: "未選択",

      select_all: "すべて",

      has_been_selected: "が選択済みです",

      application: "申請",
    },
    error_message: {
      common_error: "入力内容に不備があります。",
      first_name: "名を入力してください。",
      last_name: "姓を入力してください。",
      first_name_kana: "メイを入力してください。",
      last_name_kana: "セイを入力してください。",
      phone: "TELを入力してください。",
      password: "パスワードを入力してください。",
      password_confirmation : "確認パスワードを入力してください。",
      email: "メールアドレスを入力してください。",
      user_code: "ユーザーコードを入力してください。",
      sex: "性別を入力してください。",
      hire_date: "入社日を入力してください。",
      email_exists: "メールアドレスの値は既に存在しています。",
      first_name_kana_error: "名は全角カタカナで入力してください。",
      last_name_kana_error: "姓は全角カタカナで入力してください。",
      user_code_exists: "ユーザーコードの値は既に存在しています。",
      is_admin: "権限を入力してください。",
      email_format: "有効なメールアドレスを指定してください。",
      phone_format:
        "半角数字とハイフン（-）のみ入力できます適正な文字数ではありません。",

      date_format: "日付には年月日を入力ください。（例：2021/10/28）。",
    },
    discard: {
      title: "確認",

      content: "入力途中の内容は削除されますがよろしいですか？",
    },
    place_holder: {
      first_name: "名を入力してください",
      last_name: "姓を入力してください",
      first_name_kana: "メイを入力してください",
      last_name_kana: "セイを入力してください",
      phone: "090-1234-5678",
      password: "パスワードを入力してください",
      password_confirmation : "確認パスワードを入力してください",
      email: "メールアドレスを入力してください",
      user_code: "ユーザーコードを入力してください",
      date_select: "年月日を選択",
      hire_date: "入社日",
      section: "部門を選択してください",
      group: "グループを選択してください",
      position: "役職を入力してください",
      introduce: "紹介文を入力してください",
    },
    staff_list: "ユーザー一覧",
  },
  EDIT: {
    title: "ユーザー",

    sub_title: "サンクスカード一覧",

    breadcrumb: {
      dashboard: "ダッシュボード ",

      facility_staff: "ユーザー",

      details: "編集",
    },
    grid: {
      name: "名前",

      required: "必須",

      last_name: "姓",

      last_name_katakana: "セイ",

      first_name: "名",

      first_name_katakana: "メイ",

      alphabet: "アルファベット",

      alphabet_dec: "姓名の間にスペースを入力しないでください。",

      user_code: "ユーザーコード",

      user_code_dec: "社員コードなど個人を重複のない個人を特定できるコード。",

      contact_address: "連絡先",

      mail_address: "メールアドレス",

      phone: "任意",

      tel: "TEL",

      password: "パスワード",

      change_password: "パスワードを変更",

      password_dec: "半角英数と記号（-_!）が入力可能",

      authority: "権限",

      sex: "性別",

      select_sex: "性別を選択",

      male: "男",

      female: "女",

      select_authority: "権限を選択",

      admin: "管理者",

      editor: "編集者",

      administrator: "運営者",

      hire_date: "入社日",

      is_admin: "管理",

      is_user: "ユーザー",

      section: "部門",

      group: "グループ",

      position: "役職",

      introduce: "紹介文",

      nickname: "ニックネーム",
    },
    button: {
      create: "新規投稿",

      update: "編集",

      show: "表示",

      delete: "アーカイブ",

      search: "検索",

      cancel: "キャンセル",

      execution: "実行",

      save: "保存",
    },
    status: {
      release: "公開",

      draft: "下書き",

      archive: "アーカイブ",

      un_select: "未選択",

      select_all: "すべて",

      has_been_selected: "が選択済みです",

      application: "申請",
    },
    error_message: {
      common_error: "入力内容に不備があります。",
      first_name: "名を入力してください。",
      last_name: "姓を入力してください。",
      first_name_kana: "メイを入力してください。",
      last_name_kana: "セイを入力してください。",
      phone: "TELを入力してください。",
      password: "パスワードを入力してください。",
      email: "メールアドレスを入力してください。",
      user_code: "ユーザーコードを入力してください。",
      sex: "性別を入力してください。",
      hire_date: "入社日を入力してください。",
      email_exists: "メールアドレスの値は既に存在しています。",
      first_name_kana_error: "名は全角カタカナで入力してください。",
      last_name_kana_error: "姓は全角カタカナで入力してください。",
      user_code_exists: "ユーザーコードの値は既に存在しています。",
      email_format: "有効なメールアドレスを指定してください。",
      phone_format:
        "半角数字とハイフン（-）のみ入力できます適正な文字数ではありません。",
      date_format: "日付には年月日を入力ください。（例：2021/10/28）。",
    },
    discard: {
      title: "確認",

      content: "入力途中の内容は削除されますがよろしいですか？",
    },
    place_holder: {
      first_name: "名を入力してください",
      last_name: "姓を入力してください",
      first_name_kana: "メイを入力してください",
      last_name_kana: "セイを入力してください",
      phone: "090-1234-5678",
      password: "パスワードを入力してください",
      password_confirmation : "確認パスワードを入力してください",
      email: "メールアドレスを入力してください",
      user_code: "ユーザーコードを入力してください",
      date_select: "年月日を選択",
      hire_date: "入社日",
      section: "部門を選択してください",
      group: "グループを選択してください",
      position: "役職を入力してください",
      introduce: "紹介文を入力してください",
    },
    staff_list: "ユーザー一覧",
  },
};
