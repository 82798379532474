<template lang="">
  <div id="js-loader" class="loader"><div class="loader-animation"></div></div>
  <div class="maincontent" id="content" style="min-height: 605px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">{{ conts.title }}<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <router-link to="/admin/news"
                >{{ conts.breadcrumb.dashboard }}
              </router-link>
            </li>
            <li>
              <span>{{ conts.breadcrumb.facility_staff }} </span>
            </li>
          </ul>
        </nav>
      </div>
      <div class="command_wrap">
        <div class="leftarea btn_wrap">
          <div class="btn_norm">
            <router-link to="/admin/user/create" dusk="to_create_screen">
              ユーザー作成
            </router-link>
          </div>
        </div>
        <!--leftarea-->
      </div>
      <Delete
        :title="conts.delete.title"
        :message="conts.delete.description"
        :model="conts.pagination_url"
      />
      <!--modal-->
      <Spinner v-if="spinner" />
      <!--modal-->
      <SuccessMessage v-if="successMessage" />
      <div class="contentout_wrap">
        <div class="content_wrap">
          <div class="box_wrap">
            <h2 class="ttl_2">{{ conts.sub_title }}</h2>
            <div class="tool_wrap">
              <div class="bulktool">
                <div class="m_r50">
                  <span class="selectednum"></span
                  ><span> {{ conts.grid.has_been_selected }}</span>
                </div>
                <div class="delete commandtext js-modal-open">
                  <button
                    form="form1"
                    name="submit"
                    type="submit"
                    :value="conts.button.delete"
                    class="delete-user"
                    dusk="delete_data"
                  >
                    削除
                  </button>
                </div>
              </div>
              <div class="command_wrap_inbox">
                <div class="leftarea">
                  <div class="search_button_wrap" id="dsplanonepc">
                    <button class="button_search" @click="inputSearch()">
                      {{ conts.button.search }}<i class="fas fa-search"></i>
                    </button>
                  </div>
                  <div class="wrap m_r0 m_b10">
                    <div class="seach_contents">
                      <div class="search_box reverse sp_none">
                        <div class="search_wrap">
                          <div class="input_box">
                            <label>
                              <input
                                id="search_unique"
                                name="keyword"
                                type="text"
                                class="form_dsn"
                                autocomplete="off"
                                v-model="searchData.keyword"
                                v-on:keyup.enter="inputSearch()"
                                :placeholder="conts.place_holder.search_input"
                              />
                            </label>
                          </div>
                          <div class="megamenu b_90" style="display: none">
                            <div class="in_wrap">
                              <p
                                id="megamenu_btn"
                                class="remove_btn tooltip tip-top"
                              >
                                <i class="fas fa-times"></i>
                              </p>
                              <div class="flex_wrap">
                                <table class="formtable">
                                  <tbody>
                                    <tr>
                                      <th class="wid_20per v_m">
                                        {{ conts.search.authority }}
                                      </th>
                                      <td class="ds_b">
                                        <div class="form_outwrap">
                                          <div class="wrap wid_100per m_b0">
                                            <div class="in_wrap">
                                              <div class="select_wrap">
                                                <select
                                                  name="role_id"
                                                  v-model="searchData.is_admin"
                                                >
                                                  <option value="">
                                                    {{ conts.search.un_select }}
                                                  </option>
                                                  <option :value="true">
                                                    管理者
                                                  </option>
                                                  <option :value="false">
                                                    ユーザー
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th class="wid_20per v_m">部門</th>
                                      <td class="ds_b">
                                        <div class="form_outwrap">
                                          <div class="wrap wid_100per m_b0">
                                            <div class="in_wrap">
                                              <div class="select_wrap">
                                                <select
                                                  name="role_id"
                                                  v-model="searchData.group"
                                                >
                                                  <option value="">
                                                    部門を選択してください
                                                  </option>
                                                  <option
                                                    v-for="group in seletcedGroup"
                                                    :key="group.id"
                                                    :value="group.id"
                                                  >
                                                    {{ group.name }}
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="flex_wrap f_e">
                                <div class="btn_min">
                                  <button @click="search()">
                                    {{ conts.button.search }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="search_button">
                          <button @click="inputSearch()">
                            <i class="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                      <div class="in_wrap">
                        <div class="flex_wrap">
                          <ul class="count_list">
                            <li v-if="totalSearchCount > 0">
                              <span>検索結果： {{ totalSearchCount }} </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- filter -->
                  <div class="group-filter-block">
                    <!-- <i class="fas fa-sort sort-icon"></i> -->
                    <div class="form_outwrap">
                      <div class="wrap wid_100per m_b0">
                        <div class="in_wrap">
                          <div class="select_wrap">
                            <select
                              name="role_id"
                              v-model="searchData.group"
                              @change="groupFilterChange($event)"
                              class="groupfilter"
                            >
                              <option value="">
                                並び替えの際は部門を選択してください
                              </option>
                              <option
                                v-for="group in seletcedGroup"
                                :key="group.id"
                                :value="group.id"
                              >
                                {{ group.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <!-- filter -->
                </div>
                <!--leftarea-->
                <!--leftarea-->
                <div class="rightarea"><Pagination /></div>
                <!--rightarea-->
                <!--rightarea-->
              </div>
              <!--command_wrap-->
              <div class="in_wrap">
                <div class="flex_wrap"><ul class="search_result"></ul></div>
              </div>
            </div>
            <!--tool_wrap-->
            <div class="scr_outwrap">
              <div class="scr_wrap">
                <table class="tbl" :class="users.length > 0 ? '' : 'd-none'">
                  <thead>
                    <tr>
                      <th class="wid_5per t_l" v-if="isGroupFilter">
                      </th>
                      <th class="chkarea" v-if="!isGroupFilter">
                        <label class="checkbox_wrap" dusk="all_check">
                          <input
                            type="checkbox"
                            name=""
                            value=""
                            class="chkbox allchk allcheckbox"
                          /><span></span>
                        </label>
                      </th>
                      <th class="imgheader wid_80px">
                        {{ conts.grid.profile_logo }}
                      </th>
                      <th class="wid_10per t_l">{{ conts.grid.user_name }}</th>
                      <th class="wid_10per t_l">{{ conts.grid.user_code }}</th>
                      <th class="wid_20per t_l">
                        {{ conts.grid.mail_address }}
                      </th>
                      <th clss="t_c" v-if="!isGroupFilter">{{ conts.grid.tel }}</th>
                      <th class="t_c">{{ conts.grid.authority }}</th>
                      <th class="t_c">{{ conts.grid.position }}</th>
                      <template v-if="!isGroupFilter">
                        <th class="t_c">部門</th>
                        <th class="t_c">グループ</th>
                      </template>
                      <th class="t_c"></th>
                    </tr>
                  </thead>
                  <tbody id="draggable-block">
                    <tr v-for="user in users" 
                      :key="user.id"
                    >
                      <td class="t_c v_m drag-block handle" v-if="isGroupFilter">
                        <i class="fas fa-bars drag-icon"></i>
                      </td>
                      <td class="t_c v_m" v-if="!isGroupFilter">
                        <label class="checkbox_wrap" dusk="test">
                          <input
                            type="checkbox"
                            :disabled="isDisabled(user.id)"
                            name="status_chg[]"
                            :value="user.id"
                            class="chkbox"
                          /><span></span>
                        </label>
                      </td>
                      <td class="imgarea">
                        <div class="img_wrap">
                          <div class="user_icon">
                            <img
                              :src="'data:image/png;base64,' + user.base64"
                              alt=""
                            />
                          </div>
                        </div>
                      </td>
                      <td class="v_m">
                        <router-link
                          :to="{ name: 'User Show', params: { id: user.id } }"
                        >
                          {{ user.last_name + " " + user.first_name }}
                        </router-link>
                      </td>
                      <td class="v_m">{{ user.user_code }}</td>
                      <td class="v_m">
                        <span class="en fw_400">{{ user.email }}</span>
                      </td>
                      <td v-if="!isGroupFilter" class="t_c num v_m">
                        <span>{{ user.phone }}</span>
                      </td>
                      <td class="t_c v_m">
                        <span> {{ isAdmin(user.is_admin) }} </span>
                      </td>
                      <td class="t_c v_m">
                        <span :title="user.group ? user.group.group_role : ''">
                          {{ user.group ? user.group.group_role  : "" }}</span
                          >
                      </td>
                      <template v-if="!isGroupFilter">
                        <td class="t_c v_m">
                          <span :title="user.department ? user.department : ''">
                            {{ user.department ? user.department : "" }}</span
                          >
                        </td>
                        <td class="t_c v_m">
                          <span :title="user.group ? user.group.name : ''">
                            {{ user.group ? user.group.name : "" }}</span
                          >
                        </td>
                      </template>
                      <td class="t_c v_m command_area">
                        <div class="commandlist">
                          <div class="txt">
                            <router-link
                              dusk="to_hyoji_screen"
                              :to="{
                                name: 'User Show',
                                params: { id: user.id },
                              }"
                            >
                              {{ conts.button.show }}</router-link
                            >
                          </div>
                          <div class="txt">
                            <router-link
                              :to="{
                                name: 'User Edit',
                                params: { id: user.id },
                              }"
                            >
                              {{ conts.button.update }}</router-link
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p v-if="users.length === 0">{{ conts.search.no_results }}</p>
                <!--tbl-->
              </div>
              <!--scr_wrap-->
            </div>
            <!--scr_outwrap-->
            <div class="t_r m_t20"></div>
            <div class="command_wrap_inbox m_t10 m_b30">
              <div class="rightarea"><Pagination /></div>
              <!--rightarea-->
            </div>
          </div>
          <!--box_wrap-->
        </div>
        <!--content_wrap-->
      </div>
      <!--contentoutwrap-->
    </div>
    <!--maincontentinner-->
  </div>
</template>
<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
import SuccessMessage from "../../../components/control_panel/SuccessMessage.vue";
import Pagination from "../../../components/control_panel/Pagination.vue";
import CONST from "../../../const/control_panel/user";
import Delete from "../../../components/control_panel/Delete.vue";
import Spinner from "../../../components/control_panel/Spinner.vue";
import User from "../../../apis/admin_User";
import Groups from "../../../apis/Groups";
import "@/assets/front_component/js/timepicker/jquery.ui.datepicker-ja.min.js";
import $ from "jquery";

export default {
  data() {
    return {
      conts: CONST.LIST,
      pageParam: {
        pageNo: 1,
        perPageCount: 20,
      },
      draggedRow: null,
      draggedClone: null,
      droppedRow: null,
      draggedX: null,
      draggedY: null,
      mouseDrag: false,
      searchData: {
        keyword: "",
        is_admin: "",
        first_name: "",
        user_code: "",
        email: "",
        group: "",
      },
      isGroupFilter: false,
      publishedStartValidated: false,
      publishedEndValidated: false,
      startGreatThen: false,
      endLessThen: false,
      paginationShow: false,
      users: [],
      seletcedGroup: [],
      totalSearchCount: "",
    };
  },
  components: {
    SuccessMessage,
    Pagination,
    Delete,
    Spinner,
  },
  computed: {
    ...mapState("common", ["successMessage", "spinner", "pagination"]),
    ...mapState("user", ["allUsers", "pagination", "groups"]),
  },
  created() {
    this.setPaginationscreen(this.conts.pagination_url);

    this.getAllUsers({
      pageParam: this.pageParam,
    });

    this.getGroups();
  },
  watch: {
    allUsers(user) {
      this.users = [];
      this.users = user;
    },
    groups(groups) {
      this.seletcedGroup = groups;
    },
    pagination(page) {
      this.totalSearchCount = page.length;
    },
  },
  methods: {
    ...mapActions("user", [
      "getAllUsers",
      "inputSearchData",
      "setDeleteId",
      "getSearchData",
      "getGroupUsers",
      "getGroups",
    ]),
    ...mapActions("common", ["setSpinnerStatus", "setPaginationscreen"]),

    isAdmin(isAdmin) {
      if (isAdmin) {
        return "管理者";
      } else {
        return "ユーザー";
      }
    },
    groupFilterChange(event){
      if (this.searchData.group !== "") {
        $("#js-loader").css("display","block");

        this.isGroupFilter = true;
        
        this.bindUserList(this.searchData.group, null);
        
        let currentGroup = this.searchData.group;
        
        let app = this;
        $(function () {
          var fixHelperModified = function(e, tr) {
              tr.css("cursor", "grabbing");
              return tr;
            };
          $("#draggable-block").sortable({
            helper: fixHelperModified,
            handle: ".drag-handle",
            scroll: true,
            revert: 200,
            placeholder: "drag-over",
            start: function(e, ui){
              ui.placeholder.height(ui.item.height());
            },
            stop: function(e, ui){
              ui.item.css("cursor", "");
            },
            update: function(e, ui){
              let updatedElement = ui.item;
              let updatedElementFirstChildren = updatedElement.children('td:first');
              updatedElementFirstChildren.children('i').css("display", "none");

              //append loader
              let loaderImage = document.createElement("img");
              loaderImage.src = require("@/assets/images/spinner/spinner.gif");
              loaderImage.height = "25";
              loaderImage.width = "25";
              updatedElementFirstChildren.append(loaderImage);
              
              let updatedElementId = updatedElement.attr("id");
              let updatedElementRole = updatedElement.data("role");
              let updatedUsers = [];
              $(this).children().each(function(){
                 
                if (updatedElementRole == $(this).data("role")) {
                  updatedUsers.push($(this).attr("id"))
                }
              });

              let data = {
                group: currentGroup,
                role: updatedElementRole,
                users: updatedUsers
              }

              User.updateOrders(data).then((res)=>{
                if (res.data.result) {
                  app.bindUserList(currentGroup, updatedElementId);
                }
              }).catch((err)=> {
                console.log('user order update erroe', err);
                this.$router.push({ name: "Front Error" });
              })
            },
          });
        })
      }else{
        this.isGroupFilter = false;
        this.getAllUsers({
          pageParam: this.pageParam,
        });
      }

      if (this.isGroupFilter) {
        $(".rightarea").css("display", "none");
      }else{
        $(".rightarea").css("display", "block");
      }
    },
    bindUserList(groupId, userId){
      Groups.getGroupUsers(groupId).then((groupUsers)=>{
        this.users = groupUsers.data.data;

        $("#draggable-block").empty();

        let html = "";

        this.users.forEach((user) => {
          html += `<tr id="${user.id}" 
                  class="${userId ? (userId == user.id ? 'bg-blinking' : '') : ''}"
                  data-role="${user.group ? (user.group.group_role ? user.group.group_role: 'none') : ''}" 
                  data-order="${user.group ? user.group.user_order  : '0'}"
                >
                  <td class="t_c v_m drag-block drag-handle">
                    <div class="fas"> 
                      <p class="sort-line01"> <span class ="sort-line02"></span></p>
                    </div>
                  </td>
                  <td class="imgarea">
                    <div class="img_wrap">
                      <div class="user_icon">
                        <img
                          src="data:image/png;base64,${user.base64}"
                          alt=""
                        />
                      </div>
                    </div>
                  </td>
                  <td class="v_m">
                    <a
                      href="/admin/user/${user.id}/show"
                    >
                      ${user.last_name + " " + user.first_name}
                    </a>
                  </td>
                  <td class="v_m">${user.user_code}</td>
                  <td class="v_m">
                    <span class="en fw_400">${user.email }</span>
                  </td>
                  <td class="t_c v_m">
                    <span>${ this.isAdmin(user.is_admin) }</span>
                  </td>
                  <td class="t_c v_m">
                    <span title="${user.group ? user.group.group_role : ''}">
                      ${user.group ? (user.group.group_role ? user.group.group_role : '-')  : "-" }</span
                      >
                  </td>
                  <td class="t_c v_m command_area">
                    <div class="commandlist">
                      <div class="txt">
                        <a
                          href="/admin/user/${user.id}/show"
                        >
                          ${this.conts.button.show}</a
                        >
                      </div>
                      <div class="txt">
                        <a
                          href="/admin/user/${user.id}/edit"
                        >
                          ${this.conts.button.update}</a
                        >
                      </div>
                    </div>
                  </td>
                </tr>`
        });

        $("#draggable-block").append(html);
        $("#js-loader").css("display","none");

      }).catch((err)=>{
        console.log('user binding error', err);
        this.$router.push({ name: "Front Error" });
      })
    },
    isDisabled(userId) {
      if (userId == this.$User.id) {
        return true;
      } else {
        return false;
      }
    },
    search() {
      this.getSearchData({
        pageParam: this.pageParam,
        searchTerms: this.searchData,
      });
      $(".megamenu").css("display", "none");
    },
    inputSearch() {
      this.inputSearchData({
        pageParam: this.pageParam,
        searchTerms: this.searchData.keyword,
      });
      $(".megamenu").css("display", "none");
    },
  },
  mounted() {
    var vueThis = this;
    /*********リンクにつける発火**** */
    $(function () {
      $(".js-modal-open").on("click", function () {
        var cntall = $(".allchk:checked").length;

        let selectedDelete = $(".tbl input:checkbox:checked");

        let allDeletedId = [];
        selectedDelete.each(function () {
          if ($(this).val() != vueThis.$User.id) {
            allDeletedId.push($(this).val());
          }
        });

        vueThis.setDeleteId(allDeletedId);

        var cnt = $(".tbl input:checkbox:checked").length;

        // var checkLength = eval(cnt - cntall);

        // if (checkLength == "1") {
        // }
        $(".modal").fadeIn();
        $("#confirmModal").addClass("open");

        return false;
      });
    });

    $(function () {
      $(document).on("click", ".allcheckbox", function () {
        $(this)
          .closest("table")
          .find("tbody :checkbox")
          .filter(function (item, value) {
            return value["value"] != vueThis.$User.id;
          })
          .prop("checked", this.checked)
          .closest("tr")
          .toggleClass("selected", this.checked);
      });
      $("tbody :checkbox").on("click", function () {
        $(this).closest("tr").toggleClass("selected", this.checked);
        $(this)
          .closest("table")
          .find(".allchk")
          .prop(
            "checked",
            $(this).closest("table").find("tbody :checkbox:checked").length ==
              $(this).closest("table").find("tbody :checkbox").length
          );
      });
    });

    $(function () {
      $(document)
        .on("click", "input:checkbox", function () {
          vueThis.deleteId = $(this).val();

          var cntall = $(".allchk:checked").length;
          var cnt = $(".tbl input:checkbox:checked").length;

          $(".selectednum").text(cnt - cntall + "件");
          if (cnt == 0) {
            $(".bulktool").removeClass("open");
            $(".command_wrap_inbox").removeClass("hide");
          } else {
            $(".bulktool").addClass("open");
            $(".command_wrap_inbox").addClass("hide");
          }
        })
        .trigger("change");
    });

    $(function () {
      var ua = navigator.userAgent;
      if (
        (ua.indexOf("iPhone") > 0 || ua.indexOf("Android") > 0) &&
        ua.indexOf("Mobile") > 0
      ) {
        // スマートフォン用処理
        $(function () {
          // ボックスを表示する
          $(".button_search").on("click", function (e) {
            $(".megamenu_sp").fadeIn("fast");
            $(".megamenu_sp").addClass("open");
            $("#drawer_bg").addClass("show");
            vueThis.publishedStartValidated = false;
            vueThis.publishedEndValidated = false;
          });
          // ボックス内をクリックした際ボックスを非表示にしない
          $(".megamenu_sp").on("click", function (event) {
            event.stopPropagation();
          });

          // // ボックス外をクリックした際ボックスを非表示にする
          $(document).on("click", function (e) {
            if (
              e.target.className !== "megamenu_sp" &&
              e.target.className !== "button_search"
            ) {
              $(".megamenu_sp").fadeOut("fast");
              $(".megamenu_sp").removeClass("open");
              $("#drawer_bg").removeClass("show");
              vueThis.publishedStartValidated = false;
              vueThis.publishedEndValidated = false;
            }
          });
        });
      } else {
        // PC用処理
        /*******検索のinoutモーダル*******/
        /*https://teratail.com/questions/35924*/

        $(function () {
          vueThis.publishedStartValidated = false;
          vueThis.publishedEndValidated = false;
          // ボックスを表示する
          $(".form_dsn").on("click", function (e) {
            $(".megamenu").fadeIn("fast");
          });

          // ボックス内をクリックした際ボックスを非表示にしない
          $(".megamenu").on("click", function (event) {
            event.stopPropagation();
          });

          // ボックス外をクリックした際ボックスを非表示にする
          $(document).on("click", function (e) {
            if (
              e.target.className !== "megamenu" &&
              e.target.className !== "form_dsn"
            ) {
              if (
                e.target.className ==
                  "ui-datepicker-prev ui-corner-all ui-state-hover ui-datepicker-prev-hover" ||
                e.target.className == "ui-icon ui-icon-circle-triangle-w"
              ) {
                return false;
              }
              if (
                e.target.className ==
                  "ui-datepicker-next ui-corner-all ui-state-hover ui-datepicker-next-hover" ||
                e.target.className == "ui-icon ui-icon-circle-triangle-e"
              ) {
                return false;
              }

              $(".megamenu").fadeOut("fast");
            }
          });
        });
      }
    });
  },
};
</script>
<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
@import "~@/assets/front_component/js/timepicker/datepicker.css";

/* .sort-icon{
  font-size: 2.5rem;
  margin-right: 1rem;
} */

.group-filter-block{
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.d-none{
  display: none;
}
</style>
